<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card>
      <v-card-title>
        被试者管理
        <AppTooltipBtn
          rounded
          btn-class="ml-6"
          color="primary"
          icon="mdi-account-plus"
          label="添加"
          tooltip="创建一个新的被试者"
          @click="showPersonInfoDialog(true)"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-4"
          color="primary"
          icon="mdi-file-excel-outline"
          label="导入"
          tooltip="通过 Excel 批量导入被试者"
          @click="isShowPersonImportDialog = true"
        />
        <AppTooltipBtn
          v-if="isAdminRole"
          rounded
          btn-class="ml-4"
          color="error"
          icon="mdi-account-remove"
          label="删除"
          tooltip="批量删除被试者"
          @click="showPersonDeleteDialog(true)"
        />
        <v-spacer></v-spacer>
        <v-text-field
          v-model="personSearchText"
          append-icon="mdi-magnify"
          label="搜索任意字段"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="mx-4"
          :headers="testPersonHeaders"
          :items="testPersonList"
          item-key="guid"
          show-select
          :single-select="false"
          :sort-by="testPersonSortBy"
          :sort-desc="testPersonSortDesc"
          v-model="selectedPersonList"
          :search="personSearchText"
          :loading="isDataTableLoading"
          loading-text="正在读取被试者列表，请稍候..."
          no-data-text="未找到任何被试者"
          no-results-text="未找到任何匹配的被试者"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageAllText: '所有',
            itemsPerPageText: '每页被试者数量：',
            itemsPerPageOptions: [5, 10, 20, 50, 100, -1]
          }"
          @toggle-select-all="testPersonToggleSelectAll"
        >
          <template
            v-slot:[`footer.page-text`]="{
              pageStart,
              pageStop,
              itemsLength
            }"
          >
            {{
              `共 ${itemsLength} 个被试者，  本页显示被试者 ${pageStart}-${pageStop} ${selectedPersonCountText}`
            }}
          </template>
          <template v-slot:[`item.testeeNum`]="{ item }">
            {{ buildTesteeNumPrefix(item.testeeNumPrefix) + item.testeeNum }}
          </template>
          <template v-slot:[`item.resultsList`]="{ value }">
            <div v-for="(res, rIdx) in value" :key="rIdx">
              <v-tooltip
                bottom
                v-for="(alert, aIdx) in res.alertList"
                :key="aIdx"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!!alert.displayName"
                    :color="`${alert.colorHex}`"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-record
                  </v-icon>
                </template>
                <span>{{ `${res.lbDispName} ${alert.displayName}` }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <AppTooltipBtn
              btn-class="mr-2"
              color="primary"
              icon="mdi-file-account-outline"
              tooltip="查看个人档案"
              @click="showPersonProfileDialog(item)"
            />
            <AppTooltipBtn
              btn-class="mr-2"
              color="primary"
              icon="mdi-pencil"
              tooltip="修改被试者信息"
              @click="showPersonInfoDialog(false, item)"
            />
            <AppTooltipBtn
              v-if="isAdminRole"
              color="error"
              icon="mdi-delete"
              tooltip="删除这个被试者"
              @click="showPersonDeleteDialog(false, item)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isShowPersonInfoDialog"
      size="small"
      persistent
      :title="isCreatingNewPerson ? '新建被试者' : '被试者信息编辑'"
      text-class="px-10"
      color="success"
      action-text="确认修改"
      :loading="isBtnLoading"
      @confirm="editPersonInfoConfirm"
      @closed="personInfoDialogClosed"
    >
      <PersonInfoEditor
        :user-entity="userEntity"
        :user-guid="userGuid"
        v-model="editingPersonInfo"
        :no-update-fields="testPersonNoUpdateFields"
        @update:no-update-fields="testPersonNoUpdateFields = $event"
        :field-config-list="fieldConfigList"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteDialog"
      size="small"
      :title="deleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isBtnLoading"
      @confirm="personDeleteConfirm"
    >
      所有的相关案例也会一起删除！
    </AppDialog>
    <AppDialog
      v-model="isShowPersonProfileDialog"
      size="large"
      :title="personProfileDialogTitle"
      cancel-text="关闭"
      @closed="personProfileDialogClosed"
    >
      <PersonPsychoProfile :person-guid="actionPersonInfo.guid || ''" />
    </AppDialog>
    <AppDialog
      v-model="isShowPersonImportDialog"
      persistent
      size="small"
      title="批量导入被试者信息"
      color="primary"
      action-text="批量导入"
      :loading="isUploading"
      @confirm="importPersonListFromFile"
      @closed="personImportDialogClosed"
    >
      <v-file-input
        v-model="uploadedFile"
        label="点击上传被试者Excel"
        prepend-icon="mdi-file-excel"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :error="!!uploadErrorMsg"
        :error-messages="uploadErrorMsg"
        :rules="fieldRules.uploadedFile"
      ></v-file-input>
      <template #action-ex>
        <v-btn
          text
          color="primary"
          :disabled="isBtnLoading"
          :loading="isBtnLoading"
          @click="downloadPersonFileTemplate"
          >下载Excel模板</v-btn
        >
      </template>
    </AppDialog>
    <AppMessageBox title="导入成功！" v-model="importSuccessMessage" />
    <AppMessageBox title="发生错误" v-model="errorMessage" />
  </div>
</template>

<script>
import PersonInfoEditor from "@/components/PersonInfoEditor";
import PersonPsychoProfile from "@/components/PersonPsychoProfile";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import { mapGetters } from "vuex";
import {
  fetchTestPersonList,
  fetchTestPersonDetails,
  createNewTestPerson,
  saveByCreatingNewEditedPerson,
  bulkDeletePerson,
  downloadPersonListTemplate,
  uploadPersonListFile
} from "@/api/person";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    PersonInfoEditor,
    PersonPsychoProfile,
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox,
    AppTooltipBtn
  },

  data() {
    return {
      isLoading: false,
      isBtnLoading: false,
      isUploading: false,
      isDataTableLoading: false,
      importSuccessMessage: "",
      errorMessage: "",
      testPersonNoUpdateFields: [],
      testPersonList: [],
      selectedPersonList: [],
      testPersonSortBy: [],
      testPersonSortDesc: [],
      personSearchText: "",
      testPersonHeaders: [],
      fieldConfigList: [],
      fieldRules: {
        uploadedFile: [val => !!val || "请点击上传文件"]
      },
      // dialog
      isShowPersonInfoDialog: false,
      isShowDeleteDialog: false,
      isShowPersonImportDialog: false,
      isShowPersonProfileDialog: false,
      // action person
      isCreatingNewPerson: false,
      isBulkDeletePerson: false,
      actionPersonInfo: {
        guid: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        deptGuid: "",
        deptName: "",
        testeeNum: "",
        testeeNumPrefix: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      editingPersonInfo: {
        guid: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        deptGuid: "",
        deptName: "",
        testeeNum: "",
        testeeNumPrefix: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      emptyPersonInfo: {
        guid: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        deptGuid: "",
        deptName: "",
        testeeNum: "",
        testeeNumPrefix: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      actionPersonIndex: -1,
      // import person
      uploadedFile: null,
      uploadErrorMsg: "",
      uploadCount: 0
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      userEntity: "user/userEntity",
      roles: "user/roles"
    }),
    isAdminRole() {
      return this.roles.includes("admin");
    },
    selectedPersonCountText() {
      if (this.selectedPersonList && this.selectedPersonList.length) {
        return `，选中了${this.selectedPersonList.length}个被试者`;
      }
      return "";
    },
    personProfileDialogTitle() {
      return `个人心理档案 - ${this.actionPersonInfo.name}`;
    },
    deleteDialogTitle() {
      return this.isBulkDeletePerson
        ? `确定要删除选中的 ${this.selectedPersonList.length} 个被试者吗？`
        : `确定要删除被试者 ${
            this.actionPersonInfo.name
          } (${this.buildTesteeNumPrefix(
            this.actionPersonInfo.testeeNumPrefix
          ) + this.actionPersonInfo.testeeNum}) 吗？`;
    }
  },

  methods: {
    buildTesteeNumPrefix(prefix) {
      return prefix ? `${prefix}-` : "";
    },
    resetTestPersonSortBy() {
      this.testPersonSortBy = ["createdDate"];
    },
    resetTestPersonSortDesc() {
      this.testPersonSortDesc = [true];
    },
    async fetchUserFieldConfigList() {
      try {
        this.fieldConfigList = await getUserFieldConfig(
          this.userEntity,
          this.userGuid
        );
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async refreshTestPersonList() {
      try {
        this.isDataTableLoading = true;
        let rawPersonList = await fetchTestPersonList(
          this.userEntity,
          this.userGuid
        );
        this.testPersonList = rawPersonList.map(p => {
          return {
            ...p.testPerson,
            resultsList: p.testResultDispList
          };
        });
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isDataTableLoading = false;
    },
    buildTestPersonHeaders() {
      for (let fieldConfig of this.fieldConfigList) {
        // 对 deptGuid 来说，直接使用 deptName 是最高效的，可以不用再次获取 deptSelectList
        let fieldName =
          fieldConfig.fieldName === "deptGuid"
            ? "deptName"
            : fieldConfig.fieldName;
        if (fieldConfig.isVisibleInTable) {
          this.testPersonHeaders.push({
            text: fieldConfig.fieldAlias,
            value: fieldName
          });
        }
      }
      this.testPersonHeaders.push({ text: "更新时间", value: "createdDate" });
      this.testPersonHeaders.push({ text: "预警", value: "resultsList" });
      this.testPersonHeaders.push({
        text: "操作",
        value: "actions",
        sortable: false
      });
    },
    // ============================ shared functions ============================
    assignPersonInfo(info) {
      this.actionPersonIndex = info ? this.testPersonList.indexOf(info) : -1;
      this.actionPersonInfo = { ...info };
    },
    resetPersonInfo() {
      this.assignPersonInfo(null);
      Object.assign(this.editingPersonInfo, this.emptyPersonInfo);
    },
    // ============================ person toggle select all ============================
    testPersonToggleSelectAll({ value }) {
      if (value) {
        this.selectedPersonList = this.testPersonList;
      } else {
        this.selectedPersonList = [];
      }
    },
    // ============================ person profiles ============================
    showPersonProfileDialog(clickedItem) {
      this.assignPersonInfo(clickedItem);
      this.isShowPersonProfileDialog = true;
    },
    personProfileDialogClosed() {
      this.resetPersonInfo();
    },
    // ============================ edit person ============================
    showPersonInfoDialog(isNewPerson, clickedItem = null) {
      this.isCreatingNewPerson = isNewPerson;
      if (isNewPerson) {
        this.resetPersonInfo();
      } else {
        this.assignPersonInfo(clickedItem);
        Object.assign(this.editingPersonInfo, this.actionPersonInfo);
      }
      this.isShowPersonInfoDialog = true;
    },
    personInfoDialogClosed() {
      this.resetPersonInfo();
    },
    async editPersonInfoConfirm() {
      try {
        this.isBtnLoading = true;
        if (this.isCreatingNewPerson) {
          let newPersonGuid = await createNewTestPerson(
            this.userEntity,
            this.userGuid,
            { ...this.editingPersonInfo }
          );
          let newPersonInfo = await fetchTestPersonDetails(newPersonGuid);
          this.testPersonList.push(newPersonInfo);
        } else {
          let editedNewPersonGuid = await saveByCreatingNewEditedPerson(
            {
              ...this.editingPersonInfo
            },
            this.testPersonNoUpdateFields
          );
          this.editingPersonInfo.guid = editedNewPersonGuid;
          Object.assign(
            this.testPersonList[this.actionPersonIndex],
            this.editingPersonInfo
          );
        }
        this.isShowPersonInfoDialog = false;
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isBtnLoading = false;
    },
    // ============================ delete person ============================
    showPersonDeleteDialog(isBulkDelete, clickedItem = false) {
      this.isBulkDeletePerson = isBulkDelete;
      // 没选中的话，批量删除不弹出对话框
      // 但是单个删除的情况，永远会弹出对话框
      if (isBulkDelete && this.selectedPersonList.length) {
        this.isShowDeleteDialog = true;
      } else if (!isBulkDelete && clickedItem) {
        this.assignPersonInfo(clickedItem);
        this.isShowDeleteDialog = true;
      }
    },
    async personDeleteConfirm() {
      try {
        this.isBtnLoading = true;
        if (this.isBulkDeletePerson && this.selectedPersonList.length) {
          let deletePersonGuids = this.selectedPersonList.map(p => p.guid);
          await bulkDeletePerson(deletePersonGuids);
          // 更新显示的列表
          this.testPersonList = this.testPersonList.filter(
            c => !deletePersonGuids.includes(c.guid)
          );
        } else if (!this.isBulkDeletePerson) {
          await bulkDeletePerson([this.actionPersonInfo.guid]);
          // 更新显示的列表
          this.testPersonList.splice(this.actionPersonIndex, 1);
        }
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isShowDeleteDialog = false;
      this.isBtnLoading = false;
    },
    // ============================ import person list ============================
    personImportDialogClosed() {
      this.uploadErrorMsg = "";
      this.uploadedFile = null;
    },
    async importPersonListFromFile() {
      try {
        this.isUploading = true;
        let importGuidList = await uploadPersonListFile(
          this.userEntity,
          this.userGuid,
          this.uploadedFile
        );
        this.isShowPersonImportDialog = false;
        this.uploadCount = importGuidList ? importGuidList.length : 0;
        this.importSuccessMessage = `成功导入 ${this.uploadCount} 个被试者`;
        await this.refreshTestPersonList();
      } catch (err) {
        this.uploadErrorMsg = err && err.toString();
      }
      this.isUploading = false;
    },
    // ============================ download import person template ============================
    async downloadPersonFileTemplate() {
      try {
        this.isBtnLoading = true;
        let downloadPath = await downloadPersonListTemplate(
          this.userEntity,
          this.userGuid
        );
        downloadFile(downloadPath, "被试者批量导入模板.xlsx");
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isBtnLoading = false;
    }
  },

  async created() {
    await this.fetchUserFieldConfigList();
    this.buildTestPersonHeaders();
    this.refreshTestPersonList();
    // 初始化sort
    this.resetTestPersonSortBy();
    this.resetTestPersonSortDesc();
  }
};
</script>
